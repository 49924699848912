/* Set min-height to avoid page reflow while session loading */

.header{
  padding: 0 1em;
  background-color: rgb(0 0 0 / 15%);
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

.pageTitle{
  text-transform: uppercase;
  font-weight: normal;
  font-size: 1.125rem;
  padding-left: 10px;
  letter-spacing: 5px;
}
.signedInStatus {
  display: block;
  min-height: 4rem;
  /* width: 100%; */
}

.loading,
.loaded {
  opacity: 1;
  line-height: 41px;
}

.loading {
  opacity: 0;
}

.signedInText,
.notSignedInText {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  margin-right: 1rem;
  line-height: 1;
  vertical-align: middle;
  text-align: right;
}

.signedInText {
  padding-top: 0rem;
}

.avatar {
  border-radius: 2rem;
  float: left;
  height: 2.8rem;
  width: 2.8rem;
  background-color: white;
  background-size: cover;
  background-repeat: no-repeat;
}

.button,
.buttonPrimary {
  margin-right: -.4rem;
  font-weight: 500;
  border-radius: .3rem;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1.4rem;
  padding: .7rem .8rem;
  position: relative;
  background-color: transparent;
  color: #151a29;
}

.buttonPrimary {
  background-color: #ebcc4c;
  border: 1px solid #ebcc4c;
  text-decoration: none;
  padding: .7rem 1.4rem;
}

.buttonPrimary:hover {
  box-shadow: inset 0 0 5rem rgba(0,0,0,0.2);
  color: #fff;
  background: #151a29;
  border-color: #fff;
}

.nav{
  background-color: #1f2536;
}

.navItems {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  list-style: none;
  position: relative;
}


.navItem.inactive a,
.navItem a{
  width: 36px;
  display: block;
  padding: 20px 16px;
  color: #ddd;
  font-size: 24px;
  text-align: center;
}
.navItem.active a,
.navItem a:hover{
  display: block;
  color: #346df1;
  font-size: 24px;
  background-color: #eee;
}