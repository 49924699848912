.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  /* margin-top: 2rem; */
  opacity: 0.2;
}

.navItems {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 10px;
  text-align: right;
}

.navItem {
  display: inline-block;
  margin-right: 1rem;
}